<template>
  <div>
    <!-- <h4 v-if="isNaN(taxonomy.id)">Crear Taxonomía</h4>
    <h4 v-else>Editar Taxonomía</h4>-->
    <div>
      <b-form-group
        label="Nombre"
        label-for="input-name"
        label-cols="4"
        label-cols-sm="3"
        class="label"
      >
        <b-form-input
          id="input-name"
          name="input-name"
          v-model="$v.taxonomy.name.$model"
          :state="validateState('name')"
          aria-describedby="input-name-live-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-name-live-feedback"
          >Este campo debe tener al menos 5 caracteres.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        label="Predeterminado"
        label-for="input-active"
        label-cols="4"
        label-cols-sm="3"
        class="label"
        :description="`Define si la ${$getVisibleNames(
          'mesh.taxonomy',
          false,
          'Taxonomía'
        )} será la predefinida para la Institución.`"
        :title="`Si se activa, el
        resto de las ${$getVisibleNames(
          'mesh.taxonomy',
          true,
          'Taxonomías'
        )} se modificaran
        para que ésta sea la única activa.`"
      >
        <b-form-checkbox
          class="ml-2 mt-2"
          id="input-active"
          v-model="taxonomy.active"
        ></b-form-checkbox>
      </b-form-group>
    </div>
    <div class="row">
      <div v-if="show_delete_button" class="col" style="text-align: left">
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="askForDeleteTaxonomy"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "TaxonomyForm",
  mixins: [validationMixin],
  props: {
    Taxonomy: {
      type: Object,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      taxonomy: {
        id: this.Taxonomy ? this.Taxonomy.id : generateUniqueId(),
        name: this.Taxonomy ? this.Taxonomy.name : "",
        active: this.Taxonomy ? this.Taxonomy.active : false,
        institution: this.Taxonomy
          ? this.Taxonomy.school
          : this.$store.getters.getInstitutionId, // TODO: Conectar con la institución adecuada.
      },
    };
  },
  validations: {
    taxonomy: {
      name: {
        required,
        minLength: minLength(5),
      },
    },
  },
  computed: {
    ...mapGetters({
      taxonomies: names.TAXONOMIES,
    }),
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.taxonomy[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.taxonomy.$touch();
      if (this.$v.taxonomy.$anyError) {
        return;
      }
      if (isNaN(this.taxonomy.id)) {
        this.$store
          .dispatch(names.POST_TAXONOMY, this.taxonomy)
          .then((response) => {
            this.$emit("created", response);
            toast(
              String(
                this.$getVisibleNames("mesh.taxonomy", false, "Taxonomía") +
                  " creada."
              )
            );
          });
      } else {
        this.$store
          .dispatch(names.UPDATE_TAXONOMY, this.taxonomy)
          .then((response) => {
            this.$emit("updated", response);
            toast(
              String(
                this.$getVisibleNames("mesh.taxonomy", false, "Taxonomía") +
                  " actualizada."
              )
            );
          });
      }
      this.updateActiveTaxonomies();
    },
    askForDeleteTaxonomy() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "mesh.taxonomy",
          false,
          "Taxonomía"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_TAXONOMY, this.taxonomy.id)
            .then(() => {
              this.$emit("deleted", this.taxonomy);
            });
        }
      });
    },
    updateActiveTaxonomies() {
      if (!this.taxonomy.active) return;
      this.taxonomies.forEach((element) => {
        if (element.id != this.taxonomy.id) {
          let payload = {
            taxonomy_id: element.id,
            item: {
              active: false,
            },
          };
          this.$store.dispatch(names.PATCH_TAXONOMY, payload);
        }
      });
    },
  },
};
</script>

<style>
</style>