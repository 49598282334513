var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-form-group',{staticClass:"label",attrs:{"label":"Nombre","label-for":"input-name","label-cols":"4","label-cols-sm":"3"}},[_c('b-form-input',{attrs:{"id":"input-name","name":"input-name","state":_vm.validateState('name'),"aria-describedby":"input-name-live-feedback","size":"sm"},model:{value:(_vm.$v.taxonomy.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.taxonomy.name, "$model", $$v)},expression:"$v.taxonomy.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[_vm._v("Este campo debe tener al menos 5 caracteres.")])],1),_c('b-form-group',{staticClass:"label",attrs:{"label":"Predeterminado","label-for":"input-active","label-cols":"4","label-cols-sm":"3","description":`Define si la ${_vm.$getVisibleNames(
        'mesh.taxonomy',
        false,
        'Taxonomía'
      )} será la predefinida para la Institución.`,"title":`Si se activa, el
      resto de las ${_vm.$getVisibleNames(
        'mesh.taxonomy',
        true,
        'Taxonomías'
      )} se modificaran
      para que ésta sea la única activa.`}},[_c('b-form-checkbox',{staticClass:"ml-2 mt-2",attrs:{"id":"input-active"},model:{value:(_vm.taxonomy.active),callback:function ($$v) {_vm.$set(_vm.taxonomy, "active", $$v)},expression:"taxonomy.active"}})],1)],1),_c('div',{staticClass:"row"},[(_vm.show_delete_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.askForDeleteTaxonomy}},[_vm._v("Eliminar")])],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }